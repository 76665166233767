.category-list {
  margin-bottom: 15px;
  border: 1px dashed gray;
  margin-top: .5rem;
  background-color: #fff;
  overflow: hidden;
  .dragSource {
    float:left;
    padding: 0.5rem 1rem;
    border-right: 1px dashed gray;
    cursor: move;
  }
  .item {
    float:left;
    padding:0 10px;
    vertical-align: middle;
    line-height: 35px;
  }
  .edit {
    float: right;
    padding: 0.5rem 1rem;
    border-left: 1px dashed gray;
    cursor: pointer;
  }
}
