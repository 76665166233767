.empty {
  min-height: 20rem;
  line-height: 20rem;
  text-align: center;
  vertical-align: middle;
  color: #aaa;
}
.formTmplEditModal {
  .btn-box {
    text-align: right;
    button {
      margin-left:15px;
    }
  }
}


.test li { border: 1px solid red }
.test .Smoothie {
  height: 300px;
  border: 1px solid red
}


