.empty {
  min-height: 300px;
  text-align: center;
  vert-align: middle;
}

//QuestionSortInGroup
.QuestionSortInGroup {
  position: relative;
  border: 1px dashed gray;
  margin-bottom: .5rem;
  background-color: #fff;
  clear: both;

  .dragSource {
    padding: 0.5rem 1rem;
    float:left;
    cursor: move;
  }
  .item {
    padding: 0.5rem 1rem;
    float:left;
    border-left: 1px dashed gray;

    p {
      .btn-del {
        cursor: pointer;
        position: absolute;
        top: 0.4rem;
        right: 0.5rem;
        font-size: 20px;
      }
      .btn-del:hover {
        color: #ff325d;
      }
    }
  }
}

.questionList {
  position: fixed;
  top: 156px;
  height: 80%;
  overflow-y: scroll;
  border-radius: 13px;
  .col-lg-12 {
    padding:0 0 0 10px;
    .card-small {
      box-shadow: none;
    }
  }
  //.card {
  //  -webkit-box-shadow: 0px 5px 20px -5px rgba(0,0,0,0.75);
  //  -moz-box-shadow: 0px 5px 20px -5px rgba(0,0,0,0.75);
  //  box-shadow: 0px 5px 20px -5px rgba(0,0,0,0.75);
  //}
}


.questionDragItem {
  border: 1px dashed gray;
  margin-top: .5rem;
  background-color: #fff;
  overflow: hidden;

  .dragSource {
    float:left;
    padding: 0.5rem 1rem;
    border-right: 1px dashed gray;
    cursor: move;
  }
  .item {
    float:left;
    padding: 0.5rem 1rem;
  }
  .edit {
    float: right;
    padding: 0.5rem 1rem;
    border-right: 1px dashed gray;
    cursor: pointer;
  }
}

.questionGenModal {
  h6 {
    list-style-type : circle;
    color: #007bff;
  }
  .btn-box {
    text-align: right;
    button {
      margin: 5px;
    }

  }
}
