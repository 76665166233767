.category-mng {
  .card-header {
    position: relative;
    button {
      position: absolute;
      top: 13px;
      right: 25px;
    }
  }
  .category-list-wrap {
    padding: 10px;
    margin: 0;
  }
}

